<template>
  <StartImage parent="designs"></StartImage>
  <section class="inner-projects text-start section-margin">
    <div class="container">
      <h5>
        <span>Our Designs</span>
      </h5>
      <h3 class="section-title-margin">
        Feel free to browse all of our designs and more.
      </h3>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/design0.jpeg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/design1.jpeg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/design2.jpeg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/design3.jpeg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/design4.jpeg" loading="lazy" class="card-img" />
          </div>
        </div>
      </div>

      <div class="text-center mb-5">
        <router-link to="/contact-us" class="btn btn-beig mt-4"
          >Contact Us</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import StartImage from "../components/StartImage.vue";

export default {
  components: {
    StartImage,
  },
};
</script>

<style scoped>
.inner-projects h4 {
  margin-bottom: 20px;
}

.inner-projects h5 span {
  border-bottom: 3px solid #ffe3c2;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
}

.inner-projects h5 {
  margin-bottom: 25px;
}
</style>
